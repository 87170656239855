<template>
    <div
        class="ow-image-select border rounded-circle d-flex overflow-hidden"
        :style="backgroundStyle"
    >
        <div class="placeholder position-relative d-flex justify-content-center">
            <fa
                v-if="!src"
                icon="plus"
                class="align-self-center"
            />

            <input
                type="file"
                class="position-absolute cursor-pointer"
                :accept="accept"
                @change="onFileChange"
            >
        </div>
    </div>
</template>

<script>
import Image from '@/library/Image';

export default {
    name: 'OwImageSelect',
    props: {
        /**
         * The image to display, in base64 string or object.
         */
        value: {
            type: [String, Image],
            required: true,
        },
        /**
         * If the value is an object, this valueKey will be used to determine
         * the preview image.
         */
        valueKey: {
            type: String,
            default: '210',
        },
        /**
         * The allowed file mime types.
         */
        accept: {
            type: String,
            required: false,
            default: 'image/x-png,image/jpeg',
        },
    },
    computed: {
        /**
         * Creates the background style attributes for the current object URL.
         */
        backgroundStyle() {
            if (!this.src) return undefined;

            return {
                backgroundImage: `url(${this.src}), url(${require('@/assets/images/image-placeholder.jpg')})`,
            };
        },
        src() {
            if (typeof this.value === 'string') return this.value;

            return this._.get(this.value, `srcSet.${this.valueKey}`);
        },
    },
    methods: {
        /**
         * Handles change events from the file input, updates {@link value}
         * with the selected file in base64 string.
         *
         * @param event
         */
        onFileChange(event) {
            const files = event.target.files;

            const file = files.length > 0 ? files[0] : null;

            if (file) {
                let reader = new FileReader;

                reader.onload = (e) => {
                    this.$emit('input', e.target.result);
                };

                reader.readAsDataURL(file);
            }
        },
    },
};
</script>

<style lang="scss">
.ow-image-select {
    width: 100px;
    height: 100px;
    color: $gray-500;
    background-color: $gray-100;
    background-size: cover;
    transition: background-color .2s;

    &:hover {
        background-color: $gray-200;
    }

    > .placeholder {
        width: inherit;
        height: inherit;
        > input {
            width: inherit;
            height: inherit;
            opacity: 0;
        }
    }
}

:disabled .ow-image-select,
.ow-image-select:disabled {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
}
</style>
