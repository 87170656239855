<template>
    <b-form @submit.prevent="save">
        <input
            type="submit"
            class="d-none"
        >

        <b-row>
            <b-col
                lg="6"
                class="d-flex align-items-center pb-3"
            >
                <b-form-group
                    class="mb-0"
                    :invalid-feedback="group.errors.image"
                    :state="!group.errors.image"
                    :disabled="!editable"
                >
                    <ow-image-select v-model="group.image" />
                </b-form-group>

                <label class="col-form-label ml-4 font-weight-bold">
                    {{ $t('groups.fields.logo') }}
                </label>
            </b-col>

            <b-col lg="6">
                <b-form-group
                    :invalid-feedback="group.errors.name"
                    :label="$t('inputs.name.label')"
                    :state="!group.errors.name"
                    :disabled="!editable"
                >
                    <b-form-input
                        v-model="group.name"
                        :placeholder="$t('groups.form.inputs.name.placeholder')"
                        required
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="6">
                <b-form-group
                    v-if="group.id"
                    :invalid-feedback="group.errors.groupUsersCount"
                    :label="$t('groups.form.inputs.groupUsersCount.label')"
                    :state="!group.errors.groupUsersCount"
                    disabled
                >
                    <div class="py-1 opacity-disabled">
                        {{ group.groupUsersCount }}
                    </div>
                </b-form-group>
            </b-col>

            <b-col lg="6">
                <b-form-group
                    :invalid-feedback="group.errors.slots"
                    :label="$t('inputs.slots.label')"
                    :state="!group.errors.slots"
                    :disabled="!(canEditSlots && editable)"
                >
                    <b-form-input
                        v-model="group.slots"
                        min="0"
                        :placeholder="$t('groups.form.inputs.slots.placeholder')"
                        type="number"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-form-group
            :invalid-feedback="group.errors.description"
            :label="$t('inputs.description.label')"
            :state="!group.errors.description"
            :disabled="!editable"
        >
            <b-textarea
                v-model="group.description"
                :placeholder="$t('inputs.description.placeholder')"
            />
        </b-form-group>
    </b-form>
</template>

<script>
import OwImageSelect from '@/components/common/form/OwImageSelect';
import Role from '@/library/enumerations/Role';
import {Group} from '@/models/vue-mc/Group';

export default {
    name: 'GroupForm',
    components: {OwImageSelect},
    props: {
        group: {
            type: Group,
            required: true,
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        /**
         * Only "super"-admins can edit the number of slots,
         * might be useful to think of some permissions system if we need checks like these in the future.
         * @return {boolean}
         */
        canEditSlots() {
            return this.$me.hasRoles(Role.ADMIN);
        },
    },
    methods: {
        async save() {
            if (!this.editable) {
                return;
            }

            await this.group.save();
        },
    },
};
</script>
