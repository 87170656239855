<template>
    <div>
        <portal to="layout-default-header">
            <header-edit :title="$t('groups.create.title')">
                <template #edit-actions>
                    <div>
                        <btn-undo
                            class="btn-min-width mr-3"
                            :resource="group"
                        />

                        <btn-resource
                            class="btn-min-width"
                            :resource="group"
                            type="submit"
                            variant="primary"
                            @click="group.save()"
                        >
                            {{ $t('groups.form.actions.create') }}
                        </btn-resource>
                    </div>
                </template>
            </header-edit>
        </portal>

        <b-row align-h="center">
            <b-col
                xl="6"
                lg="7"
                md="9"
            >
                <div class="card-stack">
                    <b-card>
                        <b-card-title title-tag="h2">
                            {{ $t('groups.form.titleFields') }}
                        </b-card-title>

                        <group-form :group="group" />
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import GroupForm from '@/components/groups/Form';
import HeaderEdit from '@/components/layouts/HeaderEdit';
import {Group} from '@/models/vue-mc/Group';

export default {
    name: 'GroupsCreate',
    components: {HeaderEdit, GroupForm},
    data: () => ({
        group: new Group({
            name: '',
            slots: undefined,
            // Should be an array but is ok since we only want to display a
            // certain amount of invitations.
            invitations: {},
        }),
    }),
    created() {
        // No need to remove this listener since it will only be created once.
        this.group.on('create', this.onCreate.bind(this));
    },
    methods: {
        /**
         * Called when group is saved.
         * @param {{error: Error?, target: Group}} event
        */
        onCreate(event) {
            this.$router.push(event.target.getLocation());
        },
    },
};
</script>
