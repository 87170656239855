import Role from '@/library/enumerations/Role';
import {User} from '@/models/User';
import Collection from '@/models/vue-mc/Collection';
import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Model from '@/models/vue-mc/Model';

export class GroupUser extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            roles: [],
        };
    }

    transformations() {
        return {
            ...super.transformations(),
            user: User,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'group-users',
            fillables: {
                roles: true,
            },
        };
    }
}

export const GroupUsers = applyAttributesMixin(class GroupUsers extends Collection {
    get query() {
        const query = super.query;

        if (Role.includes(this.role)) {
            query.role = this.role;
        }

        return query;
    }

    defaults() {
        return {
            ...super.defaults(),
            role: undefined,
        };
    }

    options() {
        return {
            model: GroupUser,
        };
    }
});
