<template>
    <div class="d-flex flex-row">
        <div class="d-flex flex-column flex-grow-1">
            <h1>
                {{ title }}
            </h1>
        </div>

        <div class="d-flex flex-column justify-content-end">
            <slot name="edit-actions">
                <portal-target :name="portalTargetName" />
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderEdit',
    props: {
        title: {
            type: String,
            default: '',
        },
        portalTargetName: {
            type: String,
            default: 'edit-actions',
        },
    },
};
</script>
